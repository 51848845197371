<!-- 试验任务 -->
<template>
  <div id="test-task">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>试验任务</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <!-- <el-form-item label="变配电站">
              <el-select :popper-append-to-body="false" v-model="requestParam.stationId" placeholder="请选择" clearable>
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="变配电站">
              <el-input v-model.trim="requestParam.stationName" placeholder="请输入变配电站" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务标题">
              <el-input v-model.trim="requestParam.taskName" placeholder="请输入任务标题" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务编号">
              <el-input v-model.trim="requestParam.taskNum" placeholder="请输入任务编号" clearable></el-input>
            </el-form-item>
            <el-form-item label="任务状态">
              <el-select :popper-append-to-body="false" v-model="requestParam.state" placeholder="请选择" clearable>
                <el-option label="保存" value="1"></el-option>
                <el-option label="待执行" value="2"></el-option>
                <el-option label="执行中" value="3"></el-option>
                <el-option label="完成" value="4"></el-option>
                <el-option label="终止" value="5"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="运维负责人">
              <el-input v-model.trim="requestParam.principalName" placeholder="请输入负责人名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="运维执行人">
              <el-input v-model.trim="requestParam.operationUser" placeholder="请输入执行人名称" clearable></el-input>
            </el-form-item>
            <el-form-item class="query-date" label="计划开始时间">
              <el-date-picker v-model="requestParam.planStartTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择计划开始时间"></el-date-picker>
              <!-- <span> ~ </span>
              <el-date-picker v-model="requestParam.planEndTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择" :picker-options="pickerOptions"
                ></el-date-picker> -->
            </el-form-item>
            <el-form-item class="query-date" label="计划结束时间">
              <el-date-picker v-model="requestParam.planEndTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择计划结束时间" :picker-options="pickerOptions"
                ></el-date-picker>
            </el-form-item>
            <el-form-item class="query-date" label="实际开始时间">
              <el-date-picker v-model="requestParam.startTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择实际开始时间" ></el-date-picker>
              <!-- <span> ~ </span>
              <el-date-picker v-model="requestParam.endTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择" :picker-options="pickerOptions"
                ></el-date-picker> -->
            </el-form-item>
            <el-form-item class="query-date" label="实际结束时间">
              <el-date-picker v-model="requestParam.endTimeVo" :append-to-body="false" type="date"
                value-format="yyyy-MM-dd" placeholder="请选择实际结束时间" :picker-options="pickerOptions"
                ></el-date-picker>
            </el-form-item>

          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button v-if="isButtonPower('createButtonb')" type="primary" icon="el-icon-s-order"
              @click="createDialog">任务发布</el-button>
          </div>
        </div>

        <el-table class="table" :data="tableData">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{
            (requestParam.pageNum - 1) * requestParam.pageSize +
            scope.$index +
            1
          }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="stationName" label="变配电站" width="200">
          </el-table-column>
          <el-table-column align="center" prop="stationNumber" label="场站编码" width="150">
          </el-table-column>
          <el-table-column align="center" prop="companyName" label="运维单位" width="150">
          </el-table-column>
          <el-table-column align="center" prop="taskNum" label="试验任务编号" width="150">
          </el-table-column>
          <el-table-column align="center" prop="taskName" label="任务标题" width="150">
          </el-table-column>
          <el-table-column align="center" prop="stateDict" label="任务状态">
            <template slot-scope="scope">
              <div v-show="scope.row.stateDict === '保存'" style="color: #aed6ff">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '待执行'" style="color: #66b1ff">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '执行中'" style="color: yellow">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '完成'" style="color: #67c23a">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '终止'" style="color: red">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '通过'" style="color: #0dbc79">
                {{ scope.row.stateDict }}
              </div>
              <div v-show="scope.row.stateDict === '驳回'" style="color: #d12018">
                {{ scope.row.stateDict }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="principalName" label="运维负责人" width="100">
          </el-table-column>
          <el-table-column align="center" prop="operationUser" label="运维执行人" width="100">
          </el-table-column>
          <el-table-column align="center" prop="createName" label="创建人" width="100">
          </el-table-column>
          <el-table-column align="center" prop="resultDesc" label="执行结果" width="150">
          </el-table-column>
          <el-table-column align="center" prop="planStartTime" label="任务计划开始时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="planEndTime" label="任务计划结束时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="startTime" label="任务实际开始时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="endTime" label="任务实际结束时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="subTime" label="提交时间" width="150">
          </el-table-column>
          <el-table-column align="center" prop="remark" label="备注" width="150">
          </el-table-column>
          <el-table-column align="center" prop="count" label="试验项数量" width="100">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="175">
          </el-table-column>
          <!-- <el-table-column align="center" prop="email5" label="缺陷未处理数" width="175">
          </el-table-column> -->
          <el-table-column align="center" label="操作" prop="operate" width="250" fixed="right">
            <template slot-scope="scope">
              <div class="operate">
                <div class="flex-row" v-show="scope.row.state === 1">
                  <div v-if="isButtonPower('submitButtonb')">
                    <el-button type="text" @click="submitDialog(scope.row)">提交</el-button>
                    <span>|</span>
                  </div>
                  <div v-if="isButtonPower('editButtonb')">
                    <el-button type="text" @click="updateDialog(scope.row)">编辑</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div v-show="scope.row.state === 2">
                  <div v-if="isButtonPower('executeButtonb')">
                    <el-button type="text" @click="statusDialog(scope.row, 3)">执行</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div v-show="scope.row.state === 3">
                  <div v-if="isButtonPower('completeButtonb')">
                    <el-button type="text" @click="executeDialog(scope.row, 4)">完成</el-button>
                    <span>|</span>
                  </div>
                </div>

                <div v-show="scope.row.state === 2 || scope.row.state === 3">
                  <div v-if="isButtonPower('	stopButtonb')">
                    <el-button type="text" @click="statusDialog(scope.row, 5)">终止</el-button>
                    <span>|</span>
                  </div>
                </div>
                <div>
                  <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
                  <span>|</span>
                </div>
                <div v-if="isButtonPower('deleteButtonb')">
                  <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="查看详情" :visible.sync="viewVisible" width="800px" @close="detailsClose">
      <div class="dialog-content">
        <div class="details-title">
          <div class="square"></div>
          <div>任务明细</div>
        </div>
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="detailsData">
          <el-form-item label="电站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>

          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维负责人">
            <el-input v-model="detailsData.principalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUser" :readonly="true"></el-input>
          </el-form-item>

          <!-- <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item> -->
          <el-form-item label="计划开始时间">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间" class="">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际结束时间" class="">
            <el-input v-model="detailsData.endTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.subTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="3" v-model="detailsData.remark" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item v-if="pictureUrl" label="图片">
            <el-image style="width: 200px; height: 98px" :src="pictureUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
          </el-form-item>
          <div class="interval"></div>
          <div class="details-title">
            <div class="square"></div>
            <div>试验项内容</div>
          </div>
          <div v-show="selectTestTask.length === 0" class="no-data">
            暂无数据
          </div>
          <el-form-item v-show="selectTestTask.length !== 0" v-for="(item, index) in selectTestTask" :key="index">
            <div class="select">
              <div class="select-title" :class="item.showStatus ? 'selected-inspection' : 'default-inspection'
            " @click="selectChange(item)">
                <span>{{ item.experimentName }}</span>
                <div><i class="el-icon-arrow-down"></i></div>
              </div>

              <div v-show="item.showStatus &&
            item.operationExperimentItemPojos.length !== 0
            " class="select-content" v-for="(element, key) in item.operationExperimentItemPojos" :key="key">
                <span>{{ element.itemName }}</span>
              </div>
              <div v-show="item.showStatus &&
            item.operationExperimentItemPojos.length === 0
            " class="no-data">
                暂无数据
              </div>
            </div>
          </el-form-item>
          <div class="" v-if="detailsData.state > 2">
            <div class="details-title">
              <div class="square"></div>
              <div>执行结果</div>
            </div>
            <!-- <el-form-item label="备注">
              <el-input type="textarea" v-model="detailsData.remark" autosize :readonly="true"></el-input>
            </el-form-item> -->
            <el-form-item label="执行结果">
              <el-input type="textarea" v-model="detailsData.resultDesc" :rows="3" :readonly="true"></el-input>
            </el-form-item>
            <el-form-item v-if="resultPicture" label="图片">
              <el-image style="width: 200px; height: 98px" :src="resultPicture" fit="cover"
                :preview-src-list="previewList"></el-image>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog :title="requestType === 0 ? '新建试验任务' : '修改试验任务'" :visible.sync="dialogVisible" width="800px"
      @close="dialogClose('taskForm')">
      <div class="dialog-content">
        <div class="details-title">
          <div class="square"></div>
          <div>任务明细</div>
        </div>
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="taskForm" :rules="rules">
          <el-form-item label="变配电站" prop="stationId">
            <el-input  style="width: 330px" placeholder="请选择变配电站" v-model="stationName" :readonly="true">
              <template slot="append">
                <span @click="selectStation">选择配电站</span>
              </template>
            </el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="任务标题" prop="taskName">
            <el-input class="" placeholder="请输入任务标题" v-model.trim="taskForm.taskName">
            </el-input>
          </el-form-item>
          <div></div>
          <el-form-item label="运维单位" prop="companyId">
            <!-- <el-input class="block" placeholder="请输入任务标题" v-model.trim="taskForm.taskName">
            </el-input> -->
            <el-select :popper-append-to-body="false" clearable v-model="taskForm.companyId" placeholder="请选择运维单位"
              @change="changeObtainUser">
              <el-option v-for="item in ComList" :key="item.companyId" :label="item.companyName"
                :value="item.companyId"></el-option>
            </el-select>
          </el-form-item>
          <!-- <div class=""></div> -->
          <el-form-item label="单位负责人" >
            <!-- <el-select :popper-append-to-body="false" v-model="taskForm.principalId" placeholder="请选择负责人">
              <el-option v-for="item in userData" :key="item.id" :label="item.userName" :value="item.id"></el-option>
            </el-select> -->
            <el-input placeholder="请输入单位负责人" :readonly="true" clearable v-model.trim="approverName"></el-input>
          </el-form-item>
          <el-form-item label="运维负责人" prop="principalId">
            <el-select :popper-append-to-body="false" v-model="taskForm.principalId" placeholder="请选择运维负责人">
              <el-option v-for="item in yunweiUserData" :key="item.userId" :label="item.userName" :value="item.userId"></el-option>
            </el-select>
            <!-- <el-input placeholder="请输入负责人" :readonly="true" clearable v-model.trim="approverName"></el-input> -->
          </el-form-item>
          <el-form-item label="运维执行人" prop="operationUserId">
            <el-select :popper-append-to-body="false" v-model="taskForm.operationUserId" placeholder="请选择运维执行人">
              <el-option v-for="item in userData" :key="item.userId" :label="item.userName"
                :value="item.userId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="计划开始时间" prop="planStartTime">
            <el-date-picker v-model="taskForm.planStartTime" type="datetime" placeholder="请选择计划开始时间"
              :picker-options="pickerOptionsStart" value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false"
              @change="checkDateTime"></el-date-picker>
          </el-form-item>

          <el-form-item label="计划完成时间" prop="planEndTime">
            <el-date-picker v-model="taskForm.planEndTime" type="datetime" placeholder="请选择计划完成时间"
              value-format="yyyy-MM-dd HH:mm:ss" :append-to-body="false" @change="checkDateTime"
              :picker-options="pickerOptionsEnd"></el-date-picker>
          </el-form-item>
         

          <!-- <el-form-item label="备注">
            <div class="form-remark">
              <el-input
                placeholder="请输入备注信息"
                type="textarea"
                :rows="3"
                v-model="taskForm.remark"
              ></el-input>
              <el-upload
                action=""
                :auto-upload="false"
                :limit="1"
                :file-list="fileList"
                :on-change="pictureChange"
                list-type="picture-card"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </el-form-item> -->
          <el-form-item label="备注">
            <el-input placeholder="请输入备注信息" type="textarea" :rows="3" v-model.trim="taskForm.remark"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-upload action="" :auto-upload="false" :limit="1" accept="image/*" :file-list="fileList"
              :on-change="pictureChange" list-type="picture" :on-remove="handleRemove">
              <el-button size="small" type="primary">点击上传</el-button>
              <!-- <i class="el-icon-plus"></i> -->
              <span slot="tip" class="el-upload-tip">
                只能上传jpg/png文件,且不超过5M,建议分辨率900*400
              </span>
            </el-upload>
          </el-form-item>
          <div class="interval"></div>
          <div class="details-title">
            <div class="square"></div>
            <div>试验项内容</div>
          </div>
          <div v-show="testItem.length === 0" class="no-data">暂无数据</div>
          <el-form-item v-show="testItem.length !== 0" v-for="(item, index) in testItem" :key="index">
            <div class="select">
              <div class="select-title" :class="item.showStatus ? 'selected-inspection' : 'default-inspection'
            " @click="selectChange(item)">
                <span>{{ item.experimentName }}</span>
                <div><i class="el-icon-arrow-down"></i></div>
              </div>

              <div v-show="item.showStatus &&
            item.operationExperimentItemPojos.length !== 0
            " class="select-content" v-for="(element, key) in item.operationExperimentItemPojos" :key="key">
                <span>{{ element.itemName }}</span>
                <el-checkbox v-model="element.checked"></el-checkbox>
              </div>
              <div v-show="item.showStatus &&
            item.operationExperimentItemPojos.length === 0
            " class="no-data">
                暂无数据
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeStatus(1, 'taskForm')">保存</el-button>
        <el-button v-if="requestType === 0" type="primary" @click="changeStatus(2, 'taskForm')">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteTestTask">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="提交" :visible.sync="submitVisible" width="436px">
      <div class="dialog-text">任务一旦提交将无法修改,请确认。</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="submitVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="statusType === 3 ? '执行任务' : '终止任务'" :visible.sync="statusVisible" width="431px">
      <div class="dialog-text">
        {{ statusType === 3 ? "确定执行该任务。" : "确定终止该任务。" }}
      </div>
    
      <div slot="footer" class="dialog-footer">
        <el-button @click="statusVisible = false">取 消</el-button>
        <el-button type="primary" @click="statusConfirm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="执行结果" :visible.sync="executeVisible" width="800px" @close="dialogClose('taskForm')">
      <div class="dialog-content">
        <div class="details-title">
          <div class="square"></div>
          <div>任务明细</div>
        </div>
        <el-form class="dialog-form" ref="taskForm" :inline="true" :model="executeResult" :rules="executeResultRules">
          <el-form-item label="电站名称">
            <el-input v-model="detailsData.stationName" :readonly="true"></el-input>
          </el-form-item>
          <div class=""></div>
          <el-form-item label="任务名称">
            <el-input v-model="detailsData.taskName" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="任务编号">
            <el-input v-model="detailsData.taskNum" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维单位">
            <el-input v-model="detailsData.companyName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true">
            </el-input>
          </el-form-item>
          <el-form-item label="创建人">
            <el-input v-model="detailsData.createName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位负责人">
            <el-input v-model="detailsData.companyPrincipalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维负责人">
            <el-input v-model="detailsData.principalName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="运维执行人">
            <el-input v-model="detailsData.operationUser" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <!-- <el-form-item label="任务状态">
            <el-input v-model="detailsData.stateDict" :readonly="true"></el-input>
          </el-form-item> -->
          <el-form-item label="计划开始时间">
            <el-input v-model="detailsData.planStartTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="计划结束时间" class="">
            <el-input v-model="detailsData.planEndTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际开始时间">
            <el-input v-model="detailsData.startTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="实际结束时间" class="">
            <el-input v-model="detailsData.endTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="创建时间">
            <el-input v-model="detailsData.createTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="提交时间">
            <el-input v-model="detailsData.subTime" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="3" v-model="detailsData.remark" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item v-if="pictureUrl" label="图片">
            <el-image style="width: 200px; height: 98px" :src="pictureUrl" fit="cover"
              :preview-src-list="previewList"></el-image>
          </el-form-item>

          <div class="interval"></div>
          <div class="details-title">
            <div class="square"></div>
            <div>试验项内容</div>
          </div>
          <div v-show="selectTestTask.length === 0" class="no-data">
            暂无数据
          </div>
          <el-form-item v-show="selectTestTask.length !== 0" v-for="(item, index) in selectTestTask" :key="index">
            <div class="select">
              <div class="select-title" :class="item.showStatus ? 'selected-inspection' : 'default-inspection'
            " @click="selectChange(item)">
                <span>{{ item.experimentName }}</span>
                <div><i class="el-icon-arrow-down"></i></div>
              </div>

              <div v-show="item.showStatus &&
            item.operationExperimentItemPojos.length !== 0
            " class="select-content" v-for="(element, key) in item.operationExperimentItemPojos" :key="key">
                <span>{{ element.itemName }}</span>
              </div>
              <div v-show="item.showStatus &&
            item.operationExperimentItemPojos.length === 0
            " class="no-data">
                暂无数据
              </div>
            </div>
          </el-form-item>
          <div class="details-title">
            <div class="square"></div>
            <div>执行结果</div>
          </div>
          <el-form-item label="执行结果" prop="resultDesc">
            <el-input placeholder="请输入执行结果" type="textarea" :rows="3"
              v-model.trim="executeResult.resultDesc"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <el-upload action="" :auto-upload="false" :limit="1" accept="image/*" :file-list="executeResult.fileList"
            :on-remove="deleteImg"
              :on-change="pictureChange" list-type="picture">
              <el-button size="small" type="primary">点击上传</el-button>
              <!-- <i class="el-icon-plus"></i> -->
              <span slot="tip" class="el-upload-tip">
                只能上传jpg/png文件,且不超过5M,建议分辨率900*400
              </span>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="executeVisible = false">取 消</el-button>
        <el-button type="primary" @click="executeConfirm('taskForm',1)">保 存</el-button>
        <el-button type="primary" @click="executeConfirm('taskForm',2)">完 成</el-button>
      </div>
    </el-dialog>
    <!-- 变配电站选择 -->
    <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationName"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>
<script>
import { testTaskApi } from "@/api/testTask.js";
import { inspectionFormApi } from "@/api/inspectionForm.js";
import stationDialog from "./commponents/stationDialog.vue";
import { purchaseApi } from "@/api/purchase.js";
import { getStaComList,deleteSysFile } from "@/api/testTask.js";
export default {
  name: "",
  components: { stationDialog },
  data() {
    return {
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        approverUser: "",
        stationId: "",
        operationUser: "",
        startTimeVo: null,
        endTimeVo: null,
        state: "",
        planEndTimeVo: null,
        planStartTimeVo: null,
        taskName:'',
        taskNum:'',
        stationName:'',
      },
      total: 0,
      inputValue: 1,
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      dialogStatus: false,
      submitVisible: false,
      executeVisible: false,
      statusVisible: false,
      requestType: -1,
      statusType: 0,
      detailsData: {},
      taskForm: {
        stationId: "",
        taskName: "",
        planStartTime: "",
        planEndTime: "",
        operationUserId: "",
        remark: "",
        principalId: "",
        companyId: '',
      },
      defaultData: {
        stationId: "",
        taskName: "",
        planStartTime: "",
        planEndTime: "",
        operationUserId: "",
        remark: "",
        principalId: "",
      },
      stationName: "",
      id: "",
      rules: {
        stationId: [
          { required: true, message: "请选择变配电站", trigger: "change" },
        ],
        taskName: [
          { required: true, message: "请输入任务标题", trigger: "blur" },
        ],
        operationInspectionFormId: [
          { required: true, message: "请选择巡检单", trigger: "change" },
        ],
        companyId: [
          { required: true, message: "请选择运维单位", trigger: "change" },
        ],
        planStartTime: [
          { required: true, message: "请选择计划开始时间", trigger: "change" },
        ],
        planEndTime: [
          { required: true, message: "请选择计划完成时间", trigger: "change" },
        ],
        operationUserId: [
          { required: true, message: "请选择执行人", trigger: "change" },
        ],
        principalId: [
          { required: true, message: "请选择运维负责人", trigger: "change" },
        ],
        resultDesc: [
          { required: true, message: "请输入执行结果", trigger: "blur" },
        ],
      },
      fileList: [],
      file: [],
      previewList: [],
      executeResult: {
        resultDesc: "",
        fileList: []
      },
      executeResultRules:{
        resultDesc: [
          { required: true, message: "请输入执行结果", trigger: "blur" },
        ],
      },
      stationId: 0,
      pictureUrl: "",
      userData: [],
      yunweiUserData:[],
      testItem: [],
      stationData: [],
      selectTestTask: [],
      allButtonPower: [],
      pickerOptionsStart: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 86400000;
        },
      },
      resultPicture: '',
      approverName: '',
      ComList: [],
    };
  },

  created() {
    this.$store.commit("increment", "运维管理");
    this.$store.commit("selectChild", "试验任务");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryTestTask();
    this.queryStationName();
    // this.queryUser();
    this.queryTestItem();
    this.allButtonPower = sessionStorage.getItem('roleInfo');
  },

  methods: {
    //获取运维单位列表
    gatComList() {
      getStaComList(this.taskForm.stationId).then(res => {
        this.ComList = res.data
        if (this.taskForm.companyId) {
          this.obtainUser(this.taskForm.companyId)
        }
      })
    },

    //选择运维单位
    changeObtainUser(value) {
      console.log(value);
      let obj = this.ComList.filter(item => item.companyId === value)[0]
      console.log(obj);
      //contactNameId
      // this.taskForm.principalId = obj.contactNameId
      this.approverName = obj.contactName
      this.userData = obj.comUserList
      this.yunweiUserData = obj.fuzerenUserList
      if (this.taskForm.operationUserId) {
        this.taskForm.operationUserId = null
      }
      if (this.taskForm.principalId) {
        this.taskForm.principalId = null
      }
    },
    //选择运维单位
    obtainUser(value) {
      console.log('1111', value);
      let obj = this.ComList.filter(item => item.companyId === value)[0]
      console.log(obj);
      //contactNameId
      // this.taskForm.principalId = obj.contactNameId
      this.approverName = obj.contactName
      this.userData = obj.comUserList
      this.yunweiUserData = obj.fuzerenUserList
    },
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    getStationName(value) {
      if (value.id) {
        this.taskForm.stationId = value.id;
        this.stationName = value.stationName;
        this.taskForm.companyId=''
          this.taskForm.operationUserId='',
          this.approverName=''
          this.approverId=''
        this.gatComList()
        this.dialogStatus = false;
      }
    },
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    changeDialog(value) {
      this.dialogStatus = value;
    },
    selectStation() {
      this.dialogStatus = true;
    },
    submitConfirm() {
      if (this.requestType === 0) {
        this.dialogConfirm("taskForm");
      } else {
        this.updateTaskStatus(this.id, 2);
      }
    },
    statusDialog(item, status) {
      this.id = item.id;
      this.statusType = status;
      this.queryTaskDetails();
      this.statusVisible = true;
    },
    statusConfirm() {
      this.updateTaskStatus(this.id, this.statusType);
    },
    detailsClose() {
      this.pictureUrl = "";
    },
    dialogClose(formName) {
      this.stationId = 0;
      this.stationName = "";
      this.fileList = [];
      this.file=[]
      this.previewList = [];
      this.ComList = [];
      this.approverName = '';
      this.executeResult.resultDesc = "";
      this.executeResult.fileList=[]
      this.taskForm = {
        stationId: "",
        taskName: "",
        planStartTime: "",
        planEndTime: "",
        operationUserId: "",
        remark: "",
        principalId: "",
        companyId: '',
      };
      // Object.assign(this.taskForm, this.defaultData);
      this.$refs[formName].resetFields();
      this.testItem.forEach((element) => {
        element.showStatus = false;
        element.operationExperimentItemPojos.forEach((item) => {
          item.checked = false;
        });
      });
    },
    handleRemove(file, fileList) {
      if (fileList.length === 0) {
        this.file = [];
      }
      if (this.detailsData.sysFile.id) {
        this.deleteFile(this.detailsData.sysFile.id);
      }
    },
    pictureChange(file, fileList) {
      this.file = fileList.map((item) => item.raw);
      if (this.file.length !== 0) {
        this.$message({
          message: "图片只能上传一张",
          duration: 3000,
          customClass: "messageText",
        });
      }
    },
    deleteImg(file,fileList){
      if(file.id){
        deleteSysFile(file.id).then(res=>{
          if(res.code==200){
            this.$message({
            message: res.message,
            type:'success',
            duration: 3000,
            customClass: "messageText",
          });
          }else{
            this.$message({
            message: res.message,
            type:'error',
            duration: 3000,
            customClass: "messageText",
          });
          }
        })
      }
    },
    createDialog() {
      this.requestType = 0;
      Object.assign(this.taskForm, this.defaultData);
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;
      this.id = data.id;
      this.queryTaskDetails();
      this.stationName = data.stationName;
      this.taskForm.stationId = data.stationId;
      this.taskForm.taskName = data.taskName;
      this.taskForm.principalId = data.principalId;
      this.taskForm.companyId = data.companyId;
      this.taskForm.operationUserId = data.operationUserId;
      this.taskForm.planStartTime = data.planStartTime;
      this.taskForm.planEndTime = data.planEndTime;
      this.taskForm.remark = data.remark;
      this.gatComList()
      this.dialogVisible = true;
    },
    viewDialog(data) {
      this.id = data.id;
      this.requestType = -1;
      this.resultPicture=''
      this.queryTaskDetails();
      this.viewVisible = true;
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    submitDialog(item) {
      this.requestType = -2;
      this.id = item.id;
      this.submitVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryTestTask();
    },
    selectChange(item) {
      item.showStatus = !item.showStatus;
    },
    executeDialog(item) {
      this.id = item.id;
      this.requestType = -1;
      this.queryTaskDetails();
      this.executeVisible = true;
    },
    checkDate() {
      if (this.requestParam.startTimeVo && this.requestParam.endTimeVo) {
        const startTime = new Date(this.requestParam.startTimeVo).getTime();
        const endTime = new Date(this.requestParam.endTimeVo).getTime();
        if (startTime > endTime) {
          this.requestParam.endTimeVo = null;
        }
      }
    },
    //获取停电时长
    checkDateTime() {
      if (this.taskForm.planStartTime && this.taskForm.planEndTime) {
        const startTime = new Date(this.taskForm.planStartTime).getTime();
        const endTime = new Date(this.taskForm.planEndTime).getTime();
        if (startTime == endTime) {
          this.$message({
            message: "计划开始时间与计划结束时间不能相同",
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
          this.taskForm.planEndTime = null;
        } else if (startTime > endTime) {
          this.$message({
            message: "计划开始时间不能大于计划结束时间",
            type: "warning",
            duration: 3800,
            customClass: "messageText",
          });
          this.taskForm.planEndTime = null;
        }
      }
    },
    executeConfirm(formName,type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateTaskStatus(this.id, 4, this.executeResult.resultDesc,type);
        } else {
          return false;
        }
      });
    },
    changeStatus(state, formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (state === 2) {
            let status = false;
            this.testItem.forEach((item) => {
              item.operationExperimentItemPojos.every((element) => {
                if (element.checked) {
                  status = true;
                }
              });
            });
            if (status) {
              this.taskForm.state = state;
              this.submitVisible = true;
            } else {
              this.$message({
                message: "请选择试验项内容",
                type: "warning",
                duration: 3000,
                customClass: "messageText",
              });
            }
          } else {
            this.taskForm.state = state;
            this.dialogConfirm("taskForm");
          }
        } else {
          return false;
        }
      });
    },
    dialogConfirm(formName) {
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     if (this.requestType === 0) {
      //       this.createTestTask();
      //     } else {
      //       this.updateTestTask();
      //     }
      //   } else {
      //     return false;
      //   }
      // });
      if (this.requestType === 0) {
        this.createTestTask();
      } else {
        this.updateTestTask();
      }
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryTestTask();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryTestTask();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryTestTask();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryTestTask();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryTestTask();
    },
    queryTestTask() {
      testTaskApi.queryTestTask(this.requestParam).then((res) => {
        if (res.data.code === 200) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    queryUser() {
      testTaskApi.queryUser().then((res) => {
        if (res.code === 200) {
          this.userData = res.data;
        }
      });
    },
    queryStationName() {
      inspectionFormApi.queryStationName().then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
        }
      });
    },
    queryTestItem() {
      testTaskApi.queryTestItem().then((res) => {
        if (res.code === 200) {
          res.data.forEach((element) => {
            element.showStatus = false;
            element.operationExperimentItemPojos.forEach((item) => {
              item.checked = false;
            });
          });
          this.testItem = res.data;
        }
      });
    },
    queryTaskDetails() {
      testTaskApi.queryTaskDetails(this.id).then((res) => {
        if (res.code === 200) {
          if (this.requestType === -1) {
            this.selectTestTask = res.data.operationExperimentPojos.map(
              (item) => {
                item.showStatus = false;
                return item;
              }
            );
          }
          this.detailsData = res.data;
          if (
            this.detailsData.sysFile &&
            this.detailsData.sysFile.length !== 0
          ) {
            console.log("111111");
            if (this.requestType === -1) {
              this.pictureUrl = this.detailsData.sysFile.url;
              this.previewList.push(this.pictureUrl);
            }
            if (this.requestType === 1) {
              this.fileList = [{ url: this.detailsData.sysFile.url }];
            }
          }
          if (
            this.detailsData.resultFile &&
            this.detailsData.resultFile.length !== 0
          ) {
            if (this.requestType === -1) {
              this.resultPicture = this.detailsData.resultFile.url;
              this.previewList.push(this.resultPicture);
            }
            if (this.requestType === 1) {
              this.executeResult.fileList = [{ url: this.detailsData.resultFile.url }];
            }
          }
          if(this.detailsData.resultFile){
            this.executeResult.fileList = [{ url: this.detailsData.resultFile.url,id:this.detailsData.resultFile.id }];
          }
          this.executeResult.resultDesc = res.data.resultDesc;
          if (this.requestType === 1) {
            let selectTest = res.data.operationExperimentPojos;
            this.testItem.forEach((item) => {
              selectTest.forEach((element) => {
                if (item.id === element.id) {
                  item.operationExperimentItemPojos.forEach((all) => {
                    element.operationExperimentItemPojos.forEach((select) => {
                      if (all.id === select.id) {
                        all.checked = true;
                      }
                    });
                  });
                }
              });
            });
          }
        }
      });
    },
    unique(array) {
      return array.reduce((prev, next) => {
        return prev.includes(next) ? prev : [...prev, next];
      }, []);
    },
    createTestTask() {
      let formData = new FormData();
      if (this.file.length !== 0) {
        this.taskForm.file = this.file[0];
      }
      let array = [];
      let typeId = [];
      this.testItem.forEach((item) => {
        item.operationExperimentItemPojos.forEach((element) => {
          if (element.checked) {
            array.push(element);
            typeId.push(item.id);
          }
        });
      });
      // console.log("选择的分类id", typeId);
      // console.log("选择的详情", array);
      typeId = this.unique(typeId);
      let detailsId = [];
      for (let i = 0; i < typeId.length; i++) {
        let id = [];
        for (let j = 0; j < array.length; j++) {
          if (typeId[i] === array[j].experimentId) {
            id.push(array[j].id);
            // console.log("同一个分类下的详情id", id);
          }
          if (j === array.length - 1) {
            // console.log("循环结束");
            let string = id.toString();
            detailsId.push(string);
          }
        }
      }
      if (detailsId.length === 0) {
        this.$message({
          message: "请选择试验项内容",
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
        return;
      }
      this.taskForm.operationExperimentId = typeId.toString();
      this.taskForm.operationExperimentItemId = detailsId.join(";");
      for (let key in this.taskForm) {
        formData.append(key, this.taskForm[key]);
      }
      console.log(this.taskForm);
      testTaskApi.createTestTask(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.$store.dispatch("queryMessage");
          this.dialogVisible = false;
          this.submitVisible = false;
          this.queryTestTask();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updateTestTask() {
      let formData = new FormData();
      this.taskForm.id = this.id;
      if (this.file.length !== 0) {
        this.taskForm.file = this.file[0];
      }
      let array = [];
      let typeId = [];
      this.testItem.forEach((item) => {
        item.operationExperimentItemPojos.forEach((element) => {
          if (element.checked) {
            array.push(element);
            typeId.push(item.id);
          }
        });
      });
      typeId = this.unique(typeId);
      let detailsId = [];
      for (let i = 0; i < typeId.length; i++) {
        let id = [];
        for (let j = 0; j < array.length; j++) {
          if (typeId[i] === array[j].experimentId) {
            id.push(array[j].id);
          }
          if (j === array.length - 1) {
            let string = id.toString();
            detailsId.push(string);
          }
        }
      }
      if (detailsId.length === 0) {
        this.$message({
          message: "请选择试验项内容",
          type: "warning",
          duration: 3000,
          customClass: "messageText",
        });
        return;
      }
      this.taskForm.operationExperimentId = typeId.toString();
      this.taskForm.operationExperimentItemId = detailsId.join(";");
      for (let key in this.taskForm) {
        formData.append(key, this.taskForm[key]);
      }
      console.log(this.taskForm);
      testTaskApi.updateTestTask(formData).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.updatePicture();
          this.dialogVisible = false;
          this.queryTestTask();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updateTaskStatus(id, status, result = "",type='') {
      let formData = new FormData();
      if (this.file.length !== 0) {
        formData.append("resultFile", this.file[0]);
      }
      testTaskApi
        .updateTaskStatus(id, status, result, formData,type)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            this.$store.dispatch("queryMessage");
            this.submitVisible = false;
            this.executeVisible = false;
            this.statusVisible = false;
            this.queryTestTask();
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
          }
        });
    },
    deleteTestTask() {
      testTaskApi.deleteTestTask(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryTestTask();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updatePicture() {
      let formData = new FormData();
      if (this.file.length !== 0) {
        formData.append("taskId", this.id);
        formData.append("taskFile", this.file[0]);
      }
      testTaskApi.updatePicture(formData).then((res) => { });
    },
    deleteFile(fileId) {
      purchaseApi.deleteFile(fileId).then((res) => {
        if (res.code === 200) {
        }
      });
    },
  },
  watch: {
    "taskForm.stationId": {
      // 执行方法
      handler(newValue, oldValue) {
        if (newValue) {
          this.stationId = newValue;
        }
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
  computed: {
    pickerOptionsEnd() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.taskForm.planStartTime);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
    pickerOptions() {
      const _this = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(_this.requestParam.startTimeVo);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        },
      };
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#test-task {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

.form .query-date :deep() .el-form-item__label {
  width: 93px;
}

/* 弹窗 */
.dialog-content {
  margin: 20px 41px 0 41px;
}

.details-title {
  font-weight: 700;
  color: #0096ff;
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.square {
  width: 5px;
  height: 5px;
  background-color: #0096ff;
  margin-right: 4px;
}

.complete-title {
  font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  color: #c9d4f1;
  font-size: 16px;
  margin-bottom: 16px;
}

.interval {
  width: 100%;
  height: 2px;
  background-color: #082657;
  margin-bottom: 16px;
}

.dialog-form :deep() .el-input-group__append {
  background: #143168;
  color: #fff;
  border: 0;
  cursor: pointer;
}

.dialog-form :deep() .el-form-item__label {
  width: 93px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  box-sizing: border-box;
}

/* input */
.dialog-form :deep() .el-input {
  width: 250px;
}

.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 100%;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .block {
  width: 600px;
}

.dialog-form :deep() .el-textarea textarea {
  width: 600px;
  background-color: #061b45;
  border: 1px solid #09295b;
  /* border: 0; */
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .special .el-form-item__label {
  width: 87px;
  margin-right: 6px;
}

.el-upload-tip {
  color: #e8f4ff;
  font-size: 12px;
  margin-left: 8px;
}

/* 巡检内容 */

.select-title {
  width: 700px;
  height: 40px;
  border: 1px solid #09295b;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: pointer;
}

.select-title span {
  font-family: "Arial", sans-serif;
  color: #c9d4f1;
  font-size: 12px;
  line-height: 40px;
}

.select-content {
  padding: 0 16px;
  background-color: #041c4c;
  border-bottom: 1px solid #06224e;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.select-content span {
  color: #aed6ff;
  font-size: 12px;
}

.selected-inspection {
  background-color: #041c4c;
}

.no-data {
  height: 60px;
  color: #c9d4f1;
  font-size: 12px;
  line-height: 60px;
  text-align: center;
  background-color: #041c4c;
  margin: 16px 0;
}

/* 执行结果 */
.execute-dialog {
  margin: 16px 48px 16px 40px;
}

.execute-form :deep().el-form-item__label {
  width: 67px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}

.execute-form :deep().el-form-item__label::after {
  content: ":";
  margin: 0 3px;
}

.execute-form :deep() .el-textarea textarea {
  height: 35px;
  width: 364px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

/* 时间选择器 */
/*时间日期选择器默认样式修改 */
.dialog-form :deep() .el-picker-panel .el-input {
  width: 100%;
}

::v-deep .el-time-panel {
  background: #041c4c;
  border: 0;
  color: #aed6ff;
}

::v-deep .el-time-panel__footer {
  border-color: #aed6ff;
}

::v-deep .el-time-spinner__item {
  color: #aed6ff;
}

::v-deep .el-time-spinner__item.active:not(.disabled) {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn {
  color: #aed6ff;
}

::v-deep .el-time-panel__btn.confirm {
  color: #409eff;
}

::v-deep .el-picker-panel__footer {
  color: #aed6ff;
  background: #041c4c;
}

::v-deep .is-plain {
  background: #176ad2;
  border: 0;
  color: #fff;
}

::v-deep .el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #041c4c;
}

::v-deep .is-plain:hover {
  background: #176ad2;
  border: 0;
  color: #fff;
}

/* 设计图上的样式 ↓ */
.form-remark {
  width: 575px;
  background-color: #061b45;
  border: 1px solid #09295b;
  box-sizing: border-box;
}

.dialog-form :deep() .el-upload--picture-card {
  margin: 0 15px 15px 15px;
  width: 60px;
  height: 60px;
  background: #143168;
  border: 1px solid #143168;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.dialog-text{
  text-align: center;
  margin: 50px 0;
}
::v-deep .el-input__inner{
    height: 40px !important;
  }

/* 设计图上的样式 ↑  */
</style>
